import {defaultLang} from "../locales";

export const getCurrentLanguage = (allLangs) => {
    if (allLangs) {
        let value = localStorage.getItem("i18nextLng") ?? defaultLang.value;
        return allLangs.find(lang => lang.value === value);
    }
    return defaultLang;
};

export const getImageUrl = () => {
    return process.env.REACT_APP_BASE_IMAGE_URL;
};
