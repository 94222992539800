import React, {useEffect, useState} from "react";

// mui
import {
  Box,
  Typography,
  Grid,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import {useLocales} from "../../locales";

import {useTheme} from "@mui/material/styles";

import {useDispatch, useSelector} from "react-redux";

import {useNavigate} from "react-router-dom";

import {setTab} from "../../redux/slices/general";

import {Link} from "react-scroll";

import {useLocation} from "react-router-dom";

//--- Styles ---
import {
  contentStyle,
  dividerStyle,
  dotStyle,
  flexBox,
  flexBoxColumn,
  flexBoxSpaceBetwee,
  footerContainer,
  iconSocialStyle,
  imgStyle,
  innerFlexBoxStyle,
  lastTextStyle,
  lightContentStyle,
  smallTextStyle
} from "../../styles/footer";

import {
  flexBoxStart,
  iconCallStyle,
} from "../../styles/general";
import {CallOutlined, LocationOnOutlined, MailOutline} from "@mui/icons-material";
import {contactUsIcon} from "../../styles/contactUs";

//_______________________________________________________

export default function FooterSection() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const {translate} = useLocales();
  const currentYear = new Date().getFullYear();
  const theme = useTheme();
  const {officeInfo} = useSelector((state) => state.general);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const currentPath = location.pathname;
    const footerPaths = [
      "/cookie-policy",
      "/terms-conditions",
      "/privacy-policy",
    ];

    if (footerPaths.includes(currentPath)) {
      dispatch(setTab(null));
    } else {
      const tabMapping = {
        "/home": "0",
        "/about-us": "1",
        "/practice-areas": "2",
        "/our-people": "3",
        "/blogs": "4",
        "/careers": "5",
      };
      dispatch(setTab(tabMapping[currentPath] || null));
    }
  }, [location.pathname, dispatch]);

  const scrollToContactUs = () => {
    const contactUsElement = document.getElementById("contactUs");
    if (contactUsElement)
      contactUsElement?.scrollIntoView({behavior: "smooth"});
  }

  const handleContactUsClick = () => {
    if (location.pathname !== "/home") {
      navigate("/home");
      dispatch(setTab("0"));
      setTimeout(() => {
        scrollToContactUs();
      }, 500);
    } else {
      scrollToContactUs();
    }
  };

  const handleLogoClick = () => {
    if (location.pathname !== "/home") {
      navigate("/home");
      dispatch(setTab("0"));
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCall = () => {
    window.location.href = `tel:${officeInfo.phone_number}`;
    handleClose();
  };

  const handleMessage = () => {
    window.location.href = `sms:${officeInfo.phone_number}`;
    handleClose();
  };

  return (
    <>
      <Box sx={footerContainer}>
        <Grid container columnSpacing={{ xs: 2, md: 2, sm: 2 }}>
          {/** logo */}
          <Grid item xs={12} sm={3}>
            <Box onClick={handleLogoClick} sx={imgStyle}>
              <img
                src={`${baseUrl}/${officeInfo.logo}`}
                alt="al adwani logo"
                loading="lazy"
                width="200"
                height="100"
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={9}/>

          {/* social */}
          <Grid item xs={6} sm={4}>
            <Box sx={flexBoxColumn}>
              <Typography sx={{...contentStyle, lineHeight: "30px", textAlign: "justify"}}>
                {theme.direction === "ltr" ? officeInfo.details_en : officeInfo.details_ar}
              </Typography>
              <Box sx={{...innerFlexBoxStyle, mt: "35px", mb: {xs: "20px", sm: "0px"}}}>
                {["twitter", "linkedin", "facebook", "instagram", "whatsapp"].map((platform) => (
                  <Box key={platform} sx={iconSocialStyle}>
                    <a href={officeInfo[platform]} target="_blank" rel="noopener noreferrer">
                      <img src={`/assets/icons/${platform}.svg`} alt={`${platform} icon`}/>
                    </a>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>

          {/* quick links */}
          <Grid item xs={6} sm={4} sx={flexBoxColumn}>
            <Box sx={flexBoxColumn}>
              <Typography sx={{...smallTextStyle, lineHeight: "24px"}}>
                {translate("quick_Links")}
              </Typography>
              {[
                {path: "/about-us", label: translate("about_us")},
                {path: "/practice-areas", label: translate("key_practice_areas")},
                {path: "/blogs", label: translate("blogs")}
              ].map((link) => (
                <Box key={link.path} onClick={() => navigate(link.path)} sx={flexBox}>
                  <Box sx={dotStyle}/>
                  <Typography sx={{...contentStyle, lineHeight: "24px"}}>{link.label}</Typography>
                </Box>
              ))}
              <Link
                to="contactUs"
                smooth
                duration={500}
                onClick={handleContactUsClick}
                style={{cursor: "pointer", textDecoration: "none"}}
                href="/home"
              >
                <Box sx={flexBox}>
                  <Box sx={dotStyle}/>
                  <Typography sx={{...contentStyle, lineHeight: "24px"}}>{translate("contact_us")}</Typography>
                </Box>
              </Link>
            </Box>
          </Grid>

          {/* contact us */}
          <Grid item xs={12} sm={4}>
            <Box sx={flexBoxColumn}>
              <Box sx={flexBoxStart}>
                <LocationOnOutlined sx={{...contactUsIcon, mt: 0}}/>

                <Typography sx={{...contentStyle, textAlign: "justify", pr: "5px", pl: "5px", lineHeight: "20px"}}>
                  {theme.direction === "ltr" ? officeInfo.address_en : officeInfo.address_ar}
                </Typography>
              </Box>
              <Box onClick={handleClickOpen} sx={flexBoxStart} alignItems={"center"}>
                <CallOutlined sx={{...contactUsIcon, mt: 0}}/>
                {officeInfo.phone_number && (
                  <Typography
                    onClick={handleClickOpen}
                    sx={{...lightContentStyle, pr: "5px", pl: "5px", lineHeight: "20px", cursor: "pointer"}}
                  >
                    {theme.direction === "rtl" ? `${officeInfo.phone_number.slice(1)} +` : officeInfo.phone_number}
                  </Typography>
                )}
              </Box>
              <a href={`mailto:${officeInfo.email}`} style={{textDecoration: "none", color: "inherit"}}>
                <Box sx={flexBoxStart} alignItems={"center"}>
                  <MailOutline sx={{...contactUsIcon, mt: 0}}/>
                  <Typography sx={{...lightContentStyle, pr: "5px", pl: "5px", lineHeight: "20px"}}>
                    {officeInfo.email}
                  </Typography>
                </Box>
              </a>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={dividerStyle}/>
          </Grid>

          <Grid item xs={12} sm={4.5} mt={{xs: "10px", sm: "0px"}}>
            <Box sx={flexBoxSpaceBetwee}>
              {[
                {path: "/cookie-policy", label: translate("cookie")},
                {path: "/terms-conditions", label: translate("terms")},
                {path: "/privacy-policy", label: translate("privacy")}
              ].map((link) => (
                <Typography key={link.path} onClick={() => navigate(link.path)} sx={lastTextStyle}>
                  {link.label}
                </Typography>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} sm={3}/>

          <Grid item xs={12} sm={4.5}>
            <Typography sx={{...lastTextStyle, textAlign: "center"}}>
              © {currentYear} Aladwani Law firm. All Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{translate("options")}</DialogTitle>

        <DialogContent>
          <Typography>{translate("how")}</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCall} color="primary">
            {translate("call")}
          </Button>

          <Button onClick={handleMessage} color="primary">
            {translate("message")}
          </Button>

          <Button onClick={handleClose} color="secondary">
            {translate("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
