import React from "react";

// mui
import {Box, Typography, Grid} from "@mui/material";

import {useLocales} from "../../locales";

import {useTheme} from "@mui/material/styles";

import {useDispatch, useSelector} from "react-redux";

import {successToast} from "../../utils/toastMessage";

import {toast} from "react-toastify";

import {useForm} from "react-hook-form";

import {yupResolver} from "@hookform/resolvers/yup";

import * as Yup from "yup";

import FormProvider, {RHFTextField} from "../../components/hook-form";

import {LoadingButton} from "@mui/lab";

import {createContactUsService} from "../../redux/services/general_service";

// Styles
import {
  additionsText,
  blurTitleStyle,
  contactUsIcon,
  contentColumnBox,
  formBoxStyle,
  multiTxtFieldBorderStyle,
  overlayFormStyle,
  titleStyle,
  topSaveButtonStyle,
  txtFieldBorderStyle,
} from "../../styles/contactUs";

import {borderBottomLineStyle} from "../../styles/general";

import {
  CallOutlined,
  LocationOnOutlined,
  MailOutline
} from "@mui/icons-material";


//_______________________________________________________

export default function CountactUs() {
  const dispatch = useDispatch();

  const {officeInfo, isLoadingContactUs} = useSelector(
    (state) => state.general
  );

  const {translate} = useLocales();

  const theme = useTheme();

  const FormSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });
  const {handleSubmit} = methods;

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("first_name", data?.first_name);
    formData.append("last_name", data?.last_name);
    formData.append("email", data?.email);
    formData.append("content", data?.content);

    dispatch(createContactUsService(formData)).then((response) => {
      if (response?.payload?.status === 200) {
        successToast("تمت عملية الإضافة بنجاح");
      } else {
        response?.payload?.errors?.map((error, index) => toast.warn(error));
      }
    });
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={formBoxStyle}>
          <Box sx={overlayFormStyle}/>
          <Grid
            container
            columnSpacing={{lg: 5, sm: 2}}
            zIndex={10}
            pl={{lg: "100px", md: "30px", sm: "20px", xs: "10px"}}
            pr={{lg: "100px", md: "30px", sm: "20px", xs: "10px"}}
            pt={{lg: "60px"}}
          >
            <Grid item xs={12} sm={6} md={6} lg={7}>
              <Box sx={contentColumnBox}>
                <Typography
                  sx={{
                    ...titleStyle,
                    fontSize: {lg: "32px", md: "32px", xs: "24px", sm: "24px"},
                  }}
                >
                  {translate("contact_us")}
                </Typography>

                <Box sx={{...borderBottomLineStyle, width: "66px", mb: "10px"}}/>

                <Typography sx={blurTitleStyle}>{translate("feel")}</Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={5}>
              <Box display="flex" mb={2}>
                <LocationOnOutlined sx={contactUsIcon}/>
                <Typography
                  sx={{
                    ...additionsText,
                    textAlign: "justify",
                    lineHeight: "24px",
                  }}
                  px={2}
                >
                  {theme.direction === "ltr" ? officeInfo.address_en : officeInfo.address_ar}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" sx={{
                alignItems: {lg: "center"},
                flexDirection: {lg: "row", sm: "row", xs: "column",},
                width: {lg: "100%", sm: "80%", xs: "100%",}
              }}
              >
                <Box display="flex" mb={2} alignItems="center">
                  <CallOutlined sx={contactUsIcon}/>
                  <Typography
                    sx={{
                      ...additionsText,
                      lineHeight: "30px",
                    }}
                    px={2}
                  >
                    {officeInfo.phone_number}
                  </Typography>
                </Box>
                <Box display="flex" mb={2} alignItems="center">
                  <MailOutline sx={contactUsIcon}/>
                  <Typography
                    sx={{
                      ...additionsText,
                      lineHeight: "30px",
                    }}
                    px={2}
                  >
                    {officeInfo.email}
                  </Typography>
                </Box>
              </Box>
            </Grid>

          </Grid>
          <Grid
            container
            columnSpacing={{lg: 2, sm: 2}}
            pl={{lg: "100px", md: "30px", sm: "20px", xs: "10px"}}
            pr={{lg: "100px", md: "30px", sm: "20px", xs: "10px"}}
          >
            <Grid item xs={12} sm={4} mb={{xs: "10px"}}>
              <RHFTextField
                label={translate("first_name")}
                name="first_name"
                type="text"
                sx={txtFieldBorderStyle}
              />
            </Grid>

            <Grid item xs={12} sm={4} mb={{xs: "10px"}}>
              <RHFTextField
                label={translate("last_name")}
                name="last_name"
                type="text"
                sx={txtFieldBorderStyle}
              />
            </Grid>

            <Grid item xs={12} sm={4} mb={{xs: "10px"}}>
              <RHFTextField
                label={translate("email")}
                name="email"
                type="text"
                sx={txtFieldBorderStyle}
              />
            </Grid>

            <Grid item xs={12} sm={12} mt="30px">
              <RHFTextField
                multiline
                label={translate("write")}
                rows={4}
                name="content"
                type="text"
                sx={multiTxtFieldBorderStyle}
              />
            </Grid>

            <Grid item xs={12} sm={12} mt={"30px"}>
              <LoadingButton
                fullWidth
                size="large"
                variant="Contained"
                sx={topSaveButtonStyle}
                type="submit"
                loading={isLoadingContactUs}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </>
  );
}
