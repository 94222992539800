export const footerContainer = {
  p: {lg: "20px 60px 20px 60px", sm: "30px", md: "30px", xs: "20px"},
  background: "rgba(30, 24, 76, 1)",
  marginBottom: 0,
};

export const contentStyle = {
  fontSize: {lg: "16px", sm: "12px", xs: "12px"},
  fontWeight: {lg: 500, sm: 500},
  color: "rgba(251, 251, 251, 1)",
};

export const lightContentStyle = {
  fontSize: {lg: "16px", sm: "12px", xs: "12px"},
  fontWeight: {lg: 400, sm: 400},
  color: "rgba(251, 251, 251, 1)",
};

export const lastTextStyle = {
  fontSize: {lg: "14px", sm: "10px", xs: "10px"},
  fontWeight: {lg: 600, sm: 600, xs: 600},
  color: "rgba(255, 255, 255, 1)",
  cursor: "pointer",
  lineHeight: "25px",
};

export const smallTextStyle = {
  fontSize: {lg: "18px", sm: "14px", xs: "14px"},
  fontWeight: {lg: 600, sm: 500},
  color: "rgba(97, 204, 245, 1)",
  mb: "10px",
};

export const flexBox = {
  display: "flex",
  alignItems: "center",
  mb: "4px",
  cursor: "pointer",
};

export const flexBoxSpaceBetwee = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
};

export const innerFlexBoxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "50%",
};

export const imgStyle = {
  width: {lg: "249px", sm: "131px", xs: "131px"},
  height: {lg: "137px", sm: "72px", xs: "72px"},
};

export const dotStyle = {
  width: "5px",
  height: "5px",
  borderRadius: "50%",
  backgroundColor: "White",
  ml: "10px",
  mr: "10px",
};

export const flexBoxColumn = {
  display: "flex",
  flexDirection: "column",
  margin: {lg: "auto", sm: "none"},
};

export const dividerStyle = {
  mt: "15px",
  mb: "15px",
  color: "rgba(255, 255, 255, 1)",
};

export const iconSocialStyle = {
  width: {lg: "20px", sm: "11.86px", xs: "11.86px"},
  height: {lg: "20px", sm: "11.86px", xs: "11.86px"},
};