import React, {useEffect} from "react";

import {useDispatch} from "react-redux";

import {useLocales} from "../../locales";

import {
  getAboutUsHomeService,
  getClientsService,
  getTestimonialPeopleService,
  getkeyPracticeService,
  getSlidersService,
} from "../../redux/services/general_service";

import {setTab} from "../../redux/slices/general";

// parts
import Introduction from "../home-page/Introduction";

import AboutUs from "../home-page/AboutUs";

import PracticeAreas from "../home-page/PracticeAreas";

import Jestimonials from "../home-page/Jestimonials";

import Blogs from "../home-page/Blogs";

import People from "../home-page/People";

import ClientsPeople from "../home-page/ClientsPeople";

import CountactUs from "../home-page/CountactUs";

import FooterSection from "../footer/FooterSection";

import {Element} from "react-scroll";

//--- seo ---
import {setMetaData} from "../../redux/slices/seoSlice";
import {homeMetaData} from "../../config/metaData";
import {getCurrentLanguage} from "../../utils/helper";

//___________________________________________________________

export default function HomeTab() {
  const dispatch = useDispatch();
  const {allLangs} = useLocales();
  const currentLanguage = getCurrentLanguage(allLangs);

  useEffect(() => {
    dispatch(getAboutUsHomeService());

    dispatch(getClientsService());

    dispatch(
      getTestimonialPeopleService({
        page: 1,
        page_size: 10,
      })
    );

    dispatch(getkeyPracticeService());

    dispatch(
      getSlidersService({
        page: 1,
        page_size: 3,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(setTab("0"));
  }, [dispatch]);

  useEffect(() => {
    currentLanguage &&
    dispatch(setMetaData(homeMetaData[currentLanguage.value]));
  }, [dispatch, currentLanguage]);

  //________________________________________

  return (
    <>
      {/** first sections  */}
      <Introduction/>

      {/** about us  */}
      <AboutUs/>

      {/** third sections key_practice_areas */}
      <PracticeAreas/>

      {/** forth sections testimonials */}
      <Jestimonials/>

      {/** fifth sections  blogs  */}
      <Blogs/>

      {/** 6 sections Our People*/}
      <People/>

      {/** 7 sections  form */}
      <Element name="contactUs" id="contactUs" className="element">
        <CountactUs/>
      </Element>

      {/** 8 sections  */}
      <ClientsPeople/>

      <FooterSection/>
    </>
  );
}
