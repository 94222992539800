export const bigWhiteTextStyle = {
  fontSize: {lg: "54px", sm: "54px", xs: "32px"},
  fontWeight: {lg: 700, xs: 700},
  color: "#FFFFFF",
  mb: "15px",
  zIndex: 10,
};

export const firstBoxStyle = {
  backgroundImage: "url('/assets/images/back.png')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  alignItems: "center",
  height: "667px",
  width: "100%",
  position: "relative", // Ensure the box is positioned relative
  pb: {xs: "20px"},
  pt: {xs: "20px"},
  pl: "30px",
  pr: "30px",
  mb: "50px",
  mt: {lg: "145px", xs: "150px", md: "160px", sm: "145px"},
};

export const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(30, 24, 76, 0.5)",
  zIndex: 1,
};

export const flexBoxStart = {
  display: "flex",
  mb: "15px",
};

// ---
export const leftGridStyle = {
  display: "flex",
  flexDirection: "column",
  mb: {xs: "20px", sm: "10px", lg: "20px"},
  pl: {lg: "70px", xs: "10px", md: "60px", sm: "0px"},
  pr: {lg: "70px", md: "60px"},
};

export const rightGridStyle = {
  display: "flex",
  flexDirection: "column",
  mb: {xs: "20px", sm: "10px", lg: "20px"},
  pr: {lg: "70px", xs: "10px", md: "60px", sm: "0px"},
  pl: {md: "50px"},
};

export const titleStyle = {
  fontSize: {lg: "32px", sm: "24px", xs: "24px", md: "32px"},
  fontWeight: 700,
  color: "rgba(30, 24, 76, 1)",
  mb: "5px",
  mt: {lg: "20px"},
};

export const blueTitleStyle = {
  fontSize: {lg: "32px", sm: "20px", xs: "20px", md: "32px"},
  fontWeight: 700,
  color: "#61CCF5",
  mb: "5px",
  //textAlign: "end",
  // mt: { lg: "10px" },
  //pr: { lg: "0px", xs: "40px", md: "0px", sm: "0px" },
};

export const blackTextStyle = {
  fontSize: {lg: "20px", xs: "16px", md: "20px", sm: "14px"},
  lineHeight: {lg: "32px", xs: "26px", md: "32px", sm: "26px"},
  fontWeight: 600,
  color: "#2A2555",
  textAlign: "justify",
  mt: "10px",
};

export const imgStyle = {
  width: {lg: "492px", sm: "282px", xs: "100%", md: "492px"},
  height: {lg: "444px", sm: "331px", xs: "277px", md: "444px"},
  margin: "auto",
  mb: {xs: "20px", sm: "0px"},
};

export const lineStyle = {
  width: "125px",
  zIndex: 10,
  display: "flex",
};

export const borderBottomLineStyle = {
  width: "100px",
  zIndex: 10,
  display: "flex",
  borderBottom: "3.7px solid #61ccf6"
};

export const iconCallStyle = {
  width: {lg: "24px", sm: "14.23px", xs: "14.23px"},
  height: {lg: "24px", sm: "14.23px", xs: "14.23px"},
  flexShrink: 0,
};

export const contentStyle = {
  fontSize: {lg: "20px", sm: "16px", xs: "16px"},
  lineHeight: {lg: "32px", sm: "28px", xs: "28px"},
  fontWeight: 500,
  color: "rgba(251, 251, 251, 1)",
  zIndex: 10,
  textAlign: "justify",
};

export const flexBoxStyle = {
  display: "flex",
  alignItems: "center",
  width: "100%",
};

export const buttonAiStyle = {
  color: "#1E184C",
  fontWeight: 400,
  fontSize: {lg: "16px", sm: "12px", xs: "12px"},
  lineHeight: {lg: "20px", sm: "15px", xs: "15px"},
  bgcolor: "rgba(97, 204, 245, 1)",
  height: {lg: "48px", sm: "40px", xs: "40px"},
  width: {lg: "170px", sm: "142px", xs: "142px"},
  borderRadius: "0px",
  zIndex: 10,
  mt: {lg: "20px", xs: "0px", sm: "5px"},
  mb: {xs: "20px", sm: "0px"},
  mx: {xs: "auto", sm: 0},
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

export const boxTitleStyle = {
  display: "flex",
  alignItems: "Center",
  flexDirection: "column",
  mb: {xs: "20px", sm: "10px", lg: "20px"},
  zIndex: 10,
};

export const personStyle = {
  width: {lg: "477px", sm: "277px", xs: "auto", md: "449px"},
  height: {lg: "423px", sm: "314px", xs: "221px", md: "423px"},
  mb: {xs: "20px", sm: "0px"},
  margin: "auto",
};

export const gridStyle = {
  pl: {lg: "30px", md: "30px", sm: "20px", xs: "30px"},
  pr: {lg: "30px", md: "30px", sm: "20px", xs: "30px"},
  pt: {sm: "15px"},
  //display: "flex",
  // alignItems: "center",
};

export const textStyle = {
  fontSize: {lg: "20px", sm: "16px", xs: "16px", md: "20px"},
  lineHeight: {lg: "32px", sm: "24px", xs: "28px", md: "32px"},
  fontWeight: 500,
  color: "#1E184C",
  mb: {xs: "15px", sm: "0px"},
  pr: {lg: "24px", sm: "0px"},
  textAlign: "justify",
};
