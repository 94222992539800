import React from "react";

import {Box, Typography, Grid} from "@mui/material";

import InfiniteScroll from "react-infinite-scroll-component";

// --- Styles ---
import {
  blackTextStyle,
  blueTitleStyle, borderBottomLineStyle,
  imgStyle,
  leftGridStyle,
  titleStyle,
} from "../../../styles/general";

import {useLocales} from "../../../locales";

import {useTheme} from "@mui/material/styles";

//___________________________________________________________

function AboutUsList({length, handleLoadMore, hasMore, listItem, miniSize}) {

  const {translate} = useLocales();

  const baseUrl = process.env.REACT_APP_BASE_IMAGE_URL;

  const theme = useTheme();

  const isLtrDirection = theme.direction === "ltr";

  return (
    <InfiniteScroll
      dataLength={length}
      next={handleLoadMore}
      hasMore={hasMore}
      loader={
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={"100%"}>
          <Typography mb={3}>{translate("loading")}</Typography>
        </Box>
      } // Use ClipLoader here
      endMessage={
        <p style={{textAlign: "center", ...titleStyle}}>
          <b>{translate("you_have_seen_it_all")}</b>
        </p>
      }
    >
      <Grid container px={3} alignItems={"center"}>
        {listItem?.map((item, index) => {
          if (miniSize)
            return (
              <>
                <Grid item xs={12} sm={6} mb={3}>
                  <Box sx={leftGridStyle}>
                    <Typography sx={{...blueTitleStyle}}>
                      {theme.direction === "ltr"
                        ? item.title_en
                        : item.title_ar}
                      {}
                    </Typography>

                    <Box sx={{...borderBottomLineStyle, width: "66px"}}/>

                    <Typography sx={{...blackTextStyle}}>
                      {theme.direction === "ltr"
                        ? item.details_en
                        : item.details_ar}
                      {}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} mb={3}>
                  <Box
                    component="img"
                    src={`${baseUrl}/${item.image}`}
                    sx={imgStyle}
                    alt={"about us"}
                  />
                </Grid>
              </>
            )
          else {
            const isEven = index % 2 === 0;
            return (
              <>
                {!isEven && (
                  <Grid item xs={12} sm={6} mb={3}>
                    <Box
                      component="img"
                      src={`${baseUrl}/${item.image}`}
                      sx={imgStyle}
                      alt={"law firm"}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} mb={3}>
                  <Box sx={leftGridStyle}>

                    <Typography sx={{...blueTitleStyle}}>
                      {isLtrDirection ? item.title_en : item.title_ar}
                    </Typography>

                    <Box sx={borderBottomLineStyle}/>

                    <Typography sx={{...blackTextStyle}}>
                      {isLtrDirection ? item.details_en : item.details_ar}
                    </Typography>

                  </Box>
                </Grid>
                {isEven &&
                <Grid item xs={12} sm={6} mb={3}>
                  <Box
                    component="img"
                    src={`${baseUrl}/${item.image}`}
                    sx={imgStyle}
                    alt={"lawyer"}
                  />
                </Grid>
                }
              </>
            );
          }
        })}
      </Grid>
    </InfiniteScroll>
  );
}

export default AboutUsList;
