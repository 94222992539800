export const txtFieldBorderStyle = {
  borderRadius: "0px",

  zIndex: 100,

  "& .MuiFormLabel-root": {
    //  color: "#FFFFFF", // Change label color to white

    zIndex: 10,

    fontSize: "16px",
  },

  "& .MuiOutlinedInput-root": {
    background: "#F5F5F5",
    opacity: 0.7,
    borderRadius: "0px",

    height: "52px",

    border: "2px solid #61CCF5", // Add stroke color as border
    zIndex: 10,

    "& fieldset": {
      border: "none",
    },

    "&:hover fieldset": {
      border: "none",
    },

    "&.Mui-focused fieldset": {
      border: "none",
    },
  },

  "& input": {
    color: "#1E184CF0", // Set input text color to white

    zIndex: 100,

    "&::placeholder": {
      // color: "#FFFFFF", // Set placeholder color to white

      opacity: 1, // Ensure the placeholder is fully opaque

      zIndex: 100,
    },

    // Optional: Change text color when focused

    "&:focus": {
      color: "#1E184CF0", // Ensure text color remains white when focused
      zIndex: 10,
      //opacity: 100,
    },
  },
};

export const multiTxtFieldBorderStyle = {
  borderRadius: "0px",

  zIndex: 100,

  "& .MuiFormLabel-root": {
    //  color: "#FFFFFF", // Change label color to white

    zIndex: 10,

    fontSize: "16px",
  },

  "& .MuiOutlinedInput-root": {
    background: "#F5F5F5",
    opacity: 0.7,
    borderRadius: "0px",

    // height: "52px",

    border: "2px solid #61CCF5", // Add stroke color as border
    zIndex: 10,

    "& fieldset": {
      border: "none",
    },

    "&:hover fieldset": {
      border: "none",
    },

    "&.Mui-focused fieldset": {
      border: "none",
    },
  },

  "& input": {
    color: "#1E184CF0", // Set input text color to white

    zIndex: 100,

    "&::placeholder": {
      // color: "#FFFFFF", // Set placeholder color to white

      opacity: 1, // Ensure the placeholder is fully opaque

      zIndex: 100,
    },

    // Optional: Change text color when focused

    "&:focus": {
      color: "#1E184CF0", // Ensure text color remains white when focused
      zIndex: 10,
      //opacity: 100,
    },
  },
};

export const topSaveButtonStyle = {
  fontWeight: 700,
  zIndex: 10,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#FFFFFF",
  bgcolor: "#61CCF5",
  borderRadius: "0px",

  // width: { lg: "170px", sm: "142px", xs: "142px" },
  mx: {xs: "auto", sm: "0"},
  mb: "40px",
  height: {lg: "48px", sm: "40px", xs: "40px"},
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

export const formBoxStyle = {
  backgroundImage: "url('/assets/images/ph.png')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  //height: "529px",
  width: "100%",
  position: "relative", // Ensure the box is positioned relative
  pb: {xs: "20px"},
  pt: {xs: "20px"},
};

export const overlayFormStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "#1E184CF0",
  zIndex: 1,
};

export const blurTitleStyle = {
  fontSize: {lg: "32px", md: "32px", xs: "16px", sm: "32px"},
  fontWeight: 700,
  color: "#FFFFFF",
  mb: "10px",
  zIndex: 100,
  lineHeight: "42px",
};

export const contentColumnBox = {
  display: "flex",
  flexDirection: "column",
};

export const additionsText = {
  fontSize: {lg: "16px", md: "16px", xs: "12px", sm: "12px"},
  fontWeight: 700,
  color: "#FBFBFB",
  zIndex: 100,
};

export const titleStyle = {
  fontSize: {lg: "32px", md: "32px", xs: "24px", sm: "32px"},
  fontWeight: 700,
  color: "#61CCF5",
  mb: "10px",
  zIndex: 100,
  lineHeight: "24px",
};

export const contactUsIcon = {
  fontSize: "x-large",
  mt: "5px",
  color: "#a0e0f9"
};