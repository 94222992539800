const baseTitle = "Al Adwani";
const arBaseTitle = "شركة العدواني";
const separator = " - ";

const baseEnKeywords = "kuwait law firms, legal consultation in kuwait, kuwaiti lawyer, best lawyer, personal status cases," +
    "commercial law, company law, civil law, legal services, criminal lawyer, international arbitration," +
    "dispute resolution, partnership law, corporate lawyer, commercial lawyer, legal contracts, litigation procedures," +
    "family law, legal documentation, notary services, real estate lawyer, judicial procedures, international contracts," +
    "criminal law, government affairs, appeals, legal follow-up, family legal services, legal expertise, " +
    "attorney at law in kuwait law office, Law Firm, legal services,professional law firm, best lawyer in kuwait," +
    "law office in Kuwait, Kuwait law firm, phone number for lawyer in Kuwait, professional lawyer in kuwait," +
    "lawyer, lawfirm, trademark";

const baseArKeywords = "مكاتب محاماة بالكويت، استشارات قانونية بالكويت، محامي كويتي، افضل محامي، قضايا احوال شخصية،" +
    "قانون تجاري، قانون شركات، قانون مدني، خدمات قانونية، محامي جنائي، تحكيم دولي،" +
    "حل النزاعات، قانون شراكة، محامي شركات، محامي تجاري، عقود قانونية، اجراءات التقاضي،" +
    "قانون الاسرة، توثيق قانوني، خدمات كاتب العدل، محامي عقارات، اجراءات قضائية، عقود دولية،" +
    "قانون جنائي، شئون حكومية، استئنافات، متابعة قانونية، خدمات قانونية احوال شخصية، خبرة قانونية،" +
    "مكتب محاماة بالكويت، مكتب محاماة، خدمات قانونية،مكتب محاماة محترف، افضل محامي بالكويت،" +
    "مكتب محاماة بالكويت، مكتب محاماة بالكويت، رقم هاتف محامي بالكويت، محامي محترف بالكويت،"+
    "محامي، محاماة، علامة تجارية";

const moreEnMetaData = [

    {name: "language", content: "en"},
    {property: "og:image", content: "https://aladwani.sourcemediakw.com/assets/icons/logo.svg"},
    {property: "og:url", content: "https://aladwani.sourcemediakw.com/home"},
    {property: "og:type", content: "website"},

    {property: "og:site_name", content: "Al Adwani Law Firm in Kuwait"},
    {property: "og:locale", content: "en_US"},

    {name: "twitter:title", content: "AlAdwani - law firm in Kuwait"},
    {
        name: "twitter:description",
        content: "Al-Adwani Law Firm is an international legal firm uniquely positioned to help our clients achieve their ambitions in today's world of globalization."
    },
    {name: "twitter:url", content: "https://x.com/AlAdwaniLawFirm"},
];

const moreArMetaData = [
    {name: "language", content: "ar"},
    {property: "og:image", content: "https://www.aladwanilawfirm.com/assets/icons/logo.svg"},
    {property: "og:url", content: "https://www.aladwanilawfirm.com/home"},
    {property: "og:type", content: "website"},

    {property: "og:site_name", content: "شركة العدواني للمحاماة"},
    {property: "og:locale", content: "ar_AR"},

    {name: "twitter:title", content: "شركة العدواني للمحاماة"},
    {
        name: "twitter:description",
        content: "يعد مكتب العدواني للمحاماة مكتباً قانونياً دولياً يتمتع بموقع فريد لمساعدة عملائنا على تحقيق طموحاتهم في عالم اليوم الذي يتسم بالعولمة."
    },
    {name: "twitter:url", content: "https://x.com/AlAdwaniLawFirm"},
];

export const homeMetaData = {
    en: {
        title: baseTitle + " Law Firm",

        description: baseTitle + " Law Firm" + ", " +
            "a premier Law firm dedicated to delivering unparalleled legal services to our esteemed clients." +
            " Established in 1991 by Mr. Ayyad Al Adwani, our firm has built a distinguished reputation" +
            " through innovative methodologies and unwavering dedication to client satisfaction.",

        keywords: baseEnKeywords + "attorney, lawyer, legal assistance, expert legal advice, page for lawyer in kuwait," +
            "account of lawyer in kuwait,address for lawyer in kuwait",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "Al Adwani Law Firm - Expert Legal Services for All Your Needs in Kuwait"
            },
            {
                property: "og:description",
                content: "Al Adwani Law Firm, a premier Law firm dedicated to delivering unparalleled legal services " +
                    "to our esteemed clients in Kuwait"
            },
            ...moreEnMetaData
        ]
    },
    ar: {
        title: arBaseTitle + " للمحاماة",
        description: arBaseTitle + " للمحاماة" + "،" +
            "شركة قانونية رائدة مكرسة لتقديم خدمات قانونية لا مثيل لها لعملائنا الكرام في دولة الكويت." +
            "تأسست عام 1991 من قبل السيد عياد العدو، وقد بنى مكتبنا سمعة متميزة" +
            "من خلال أساليب مبتكرة والتزام لا يتزعزع بإرضاء العميل.",

        keywords: baseArKeywords + "محامي، مساعدة قانونية، مشورة قانونية خبيرة",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "شركة العدواني للمحاماة - خدمات قانونية احترافية لكل احتياجاتك"
            },
            {
                property: "og:description",
                content: "شركة العدواني للمحاماة، مكتب محاماة رائد مخصص لتقديم خدمات قانونية لا مثيل لها لعملائنا الكرام"
            },
            ...moreArMetaData
        ]
    }
};

export const aboutUsMetaData = {
    en: {
        title: baseTitle + separator + "About Us",
        description: baseTitle + separator + "About Us, " + "With more than 30 years of experience, Al Adwani Law Firm continues to set the standard for exceptional legal service. " +
            "Founded with a vision for excellence, our team remains committed to providing tailored solutions that meet the unique needs of each client.",
        keywords: baseEnKeywords + "about us, About Al Adwani, law firm, legal team, legal services, professional law firm",
        additionalMetaTags: [{
            property: "og:title",
            content: "About Us - Al Adwani Law Firm"
        }, {
            property: "og:description",
            content: "Learn more about Al Adwani Law Firm, our mission, our team, and our commitment to excellence in legal services"
        },
            ...moreEnMetaData
        ]
    },
    ar: {
        title: arBaseTitle + separator + " من نحن",
        description: arBaseTitle + separator + " من نحن" + " ، " +
            "مع أكثر من 30 عامًا من الخبرة، شركة العدواني للمحاماة تستمر في وضع معايير الخدمة القانونية الاستثنائية. فريقنا الذي تأسس مع رؤية للتميز، يبقى ملتزمًا بتقديم حلول مخصصة تلبي الاحتياجات الفريدة لكل عميل.",
        keywords: baseArKeywords + "من نحن, عن العدواني, شركة محاماة, فريق قانوني, خدمات قانونية, شركة محاماة محترفة",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "من نحن - شركة محاماة العدواني"
            },
            {
                property: "og:description",
                content: "تعرف على المزيد حول شركة محاماة العدواني ومهمتنا وفريقنا والتزامنا بالتميز في الخدمات القانونية"
            },
            ...moreArMetaData
        ]
    }
};

export const blogsMetaData = {
    en: {
        title: baseTitle + separator + "Blogs",
        description: baseTitle + separator + "Blogs, " +
            "Stay informed with our latest blog posts covering various legal topics, updates, and expert insights from our experienced attorneys.",
        keywords: baseEnKeywords + "blogs, legal blogs, legal insights, legal updates, law blog",
        additionalMetaTags: [{
            property: "og:title",
            content: "Blogs - Legal Insights and Updates"
        }, {
            property: "og:description",
            content: "Stay informed with our latest blog posts covering various legal topics, updates, and expert insights from our experienced attorneys"
        },
            ...moreEnMetaData
        ]
    },
    ar: {
        title: arBaseTitle + separator + " المدونات",
        description: arBaseTitle + separator + " المدونات" + " ، " +
            "ابقَ على اطلاع من خلال آخر مدوناتنا التي تغطي مواضيع قانونية متنوعة ومستجدات ورؤى الخبراء من محامينا ذوي الخبرة.",
        keywords: baseArKeywords + "مدونات, مدونات قانونية, رؤى قانونية, مستجدات قانونية, مدونة قانون",
        additionalMetaTags: [{
            property: "og:title",
            content: "المدونات - رؤى قانونية ومستجدات"
        }, {
            property: "og:description",
            content: "ابقَ على اطلاع من خلال آخر مدوناتنا التي تغطي مواضيع قانونية متنوعة ومستجدات ورؤى الخبراء من محامينا ذوي الخبرة"
        },
            ...moreArMetaData
        ]
    }
};

export const peopleMetaData = {
    en: {
        title: baseTitle + separator + "Our People",
        description: baseTitle + separator + "Our People" + ", " +
            "Get to know our team of skilled attorneys and legal professionals dedicated to providing exceptional legal services and personalized client care.",
        keywords: baseEnKeywords + "our people, legal team, attorneys, legal professionals, law office staff",
        additionalMetaTags: [{
            property: "og:title",
            content: "Our People - Meet Our Team of Legal Experts"
        }, {
            property: "og:description",
            content: "Get to know our team of skilled attorneys and legal professionals dedicated to providing exceptional legal services and personalized client care."
        }, ...moreEnMetaData]
    },
    ar: {
        title: arBaseTitle + separator + "فريقنا",
        description: arBaseTitle + separator + "فريقنا" +
            "تعرف على فريق المحامين المحترفين لدينا والمتخصصين في تقديم خدمات قانونية استثنائية ورعاية عملاء شخصية.",
        keywords: baseArKeywords + "فريقنا, فريق قانوني, محامين, مهنيين قانونيين, موظفي مكتب المحاماة",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "فريقنا - التعرف على فريق الخبراء القانونيين"
            },
            {
                property: "og:description",
                content: "تعرف على فريق المحامين المحترفين لدينا والمتخصصين في تقديم خدمات قانونية استثنائية ورعاية عملاء شخصية."
            },
            ...moreArMetaData
        ]
    }
};

export const careersMetaData = {
    en: {
        title: baseTitle + separator + "Careers",
        description: baseTitle + separator + "Careers, " +
            "Explore career opportunities at our law office and become part of a team that values excellence, integrity, and professional growth.",
        keywords: baseEnKeywords + "careers, law office jobs, legal careers, legal employment",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "Careers - Join Our Al Adwani Team"
            }, {
                property: "og:description",
                content: "Explore career opportunities at our law office and become part of a team that values excellence, integrity, and professional growth."
            },
            ...moreEnMetaData
        ]
    },
    ar: {
        title: arBaseTitle + separator + "المسارات المهنية",
        description: arBaseTitle + separator + "المسارات المهنية" + " ، " +
            "استكشف فرص العمل في مكتبنا القانوني وكن جزءًا من فريق يقدر التميز والنزاهة والنمو المهني.",
        keywords: baseArKeywords + "مسارات مهنية, وظائف مكتب المحاماة, وظائف قانونية, التوظيف القانوني",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "المسارات المهنية - انضم إلى فريق شركة العدواني"
            },
            {
                property: "og:description",
                content: "استكشف فرص العمل في مكتبنا القانوني وكن جزءًا من فريق يقدر التميز والنزاهة والنمو المهني."
            },
            ...moreArMetaData
        ]
    }
};

export const practiceAreaMetaData = {
    en: {
        title: baseTitle + separator + "Practice Areas",
        description: baseTitle + separator + "Practice Areas, " +
            "Discover our wide range of practice areas, Al Adwani is Offering tailored legal services in Kuwait and internationally, specializing in corporate law, litigation, banking, and more",
        keywords: baseEnKeywords + "Legal Services, Expert Lawyers, Kuwait Law Firm, Corporate Law, Banking Law, International Legal Services, Specialized Legal Practice, Practice Areas, Comprehensive Legal Services, Tailored Legal Solutions",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "Practice Areas - Comprehensive Legal Services"
            },
            {
                property: "og:description",
                content: "Discover our wide range of practice areas, Al Adwani is specializing in corporate law, litigation, banking, and more"
            }, ...moreEnMetaData
        ]
    },
    ar: {
        title: arBaseTitle + separator + "مجالات العمل",
        description: arBaseTitle + separator + "مجالات العمل" + " ، " +
            "اكتشف نطاقنا الواسع من مجالات الممارسة، شركة العدواني تقدم خدمات قانونية مخصصة في الكويت وعلى المستوى الدولي، متخصصة في القانون التجاري، التقاضي، البنوك، وأكثر",
        keywords: baseArKeywords + "خدمات قانونية، محامون خبراء، شركة محاماة الكويت، القانون التجاري، قانون البنوك، خدمات قانونية دولية، ممارسة قانونية متخصص، مجالات الممارسة، خدمات قانونية شاملة، حلول قانونية مخصصة",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "مجالات الممارسة - خدمات قانونية شاملة"
            },
            {
                property: "og:description",
                content: "اكتشف نطاقنا الواسع من مجالات الممارسة، شركة العدواني متخصصة في القانون التجاري، التقاضي، البنوك، وأكثر"
            },
            ...moreArMetaData
        ]
    }
};

export const privacyPolicyMetaData = {
    en: {
        title: "privacy Policy",
        description: "privacy Policy, Read Al Adwani Law Firm's privacy policy to learn how we protect your personal information and ensure your privacy.",
        keywords: baseEnKeywords + "privacy policy, data protection, information security, law firm privacy, legal privacy policy, Al Adwani Law Firm",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "Al Adwani Law Firm - Privacy Policy"
            },
            {
                property: "og:description",
                content: "Read Al Adwani Law Firm's privacy policy to learn how we protect your personal information and ensure your privacy."
            },
            ...moreEnMetaData
        ]
    },
    ar: {
        title: "سياسة الخصوصية",
        description: "سياسة الخصوصية" + "اقرأ سياسة الخصوصية لشركة العدواني للمحاماة لتتعرف على كيفية حماية معلوماتك الشخصية وضمان خصوصيتك.",
        keywords: baseArKeywords + "سياسة الخصوصية، حماية البيانات، أمن المعلومات، خصوصية شركة المحاماة، سياسة الخصوصية القانونية، شركة العدواني للمحاماة",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "شركة العدواني للمحاماة - سياسة الخصوصية"
            },
            {
                property: "og:description",
                content: "اقرأ سياسة الخصوصية لشركة العدواني للمحاماة لتتعرف على كيفية حماية معلوماتك الشخصية وضمان خصوصيتك."
            },
            ...moreArMetaData
        ]
    }
};

export const termsConditionsMetaData = {
    en: {
        title: "Terms ِAnd Conditions",
        description: "Terms ِAnd Conditions, Review the terms and conditions of Al Adwani Law Firm to understand the rules and regulations governing the use of our services.",
        keywords: baseEnKeywords + "terms and conditions, legal terms, law firm terms, Al Adwani Law Firm, service terms, legal agreement",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "Al Adwani Law Firm - Terms & Conditions"
            },
            {
                property: "og:description",
                content: "Review the terms and conditions of Al Adwani Law Firm to understand the rules and regulations governing the use of our services."
            },
            ...moreEnMetaData
        ]
    },
    ar: {
        title: "الشروط والأحكام",
        description: "الشروط والأحكام" + "راجع الشروط والأحكام الخاصة بشركة العدواني للمحاماة لفهم القواعد واللوائح التي تحكم استخدام خدماتنا.",
        keywords: baseArKeywords + "الشروط والأحكام, الشروط القانونية, شروط شركة المحاماة, شركة العدواني للمحاماة, شروط الخدمة, الاتفاقية القانونية",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "شركة العدواني للمحاماة - الشروط والأحكام"
            },
            {
                property: "og:description",
                content: "راجع الشروط والأحكام الخاصة بشركة العدواني للمحاماة لفهم القواعد واللوائح التي تحكم استخدام خدماتنا."
            },
            ...moreArMetaData
        ]
    }
};

export const cookiesMetaData = {
    en: {
        title: "Cookie Policy",
        description: "Cookie Policy, Learn about how Al Adwani Law Firm uses cookies to enhance your browsing experience and ensure the functionality of our website.",
        keywords: baseEnKeywords + "cookie policy, cookies, data tracking, website cookies, law firm cookies, Al Adwani Law Firm",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "Al Adwani Law Firm - Cookie Policy"
            },
            {
                property: "og:description",
                content: "Learn about how Al Adwani Law Firm uses cookies to enhance your browsing experience and ensure the functionality of our website."
            },
            ...moreEnMetaData
        ]
    },
    ar: {
        title: "سياسة ملفات تعريف الارتباط",
        description: "سياسة ملفات تعريف الارتباط" + "تعرف على كيفية استخدام شركة العدواني للمحاماة للكوكيز لتحسين تجربة التصفح وضمان فعالية موقعنا الإلكتروني.",
        keywords: baseArKeywords + "سياسة الكوكيز، الكوكيز، تتبع البيانات، كوكيز الموقع، كوكيز شركة المحاماة، شركة العدواني للمحاماة",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "شركة العدواني للمحاماة - سياسة الكوكيز"
            },
            {
                property: "og:description",
                content: "تعرف على كيفية استخدام شركة العدواني للمحاماة للكوكيز لتحسين تجربة التصفح وضمان فعالية موقعنا الإلكتروني."
            },
            ...moreArMetaData
        ]
    }
};
