import React, {useEffect, useState} from "react";

import {Box, Typography, Grid} from "@mui/material";

import {getAboutUsService} from "../../redux/services/general_service";

import {useDispatch, useSelector} from "react-redux";

import {allLangs, useLocales} from "../../locales";

import useResponsive from "../../hooks/useResponsive";

// Footer
import FooterSection from "../footer/FooterSection";

// --- styles ---
import {
  bigWhiteTextStyle,
  borderBottomLineStyle,
  firstBoxStyle,
  overlayStyle,
} from "../../styles/general";

//--- seo ---
import {setMetaData} from "../../redux/slices/seoSlice";
import {aboutUsMetaData} from "../../config/metaData";
import {getCurrentLanguage} from "../../utils/helper";

//--
import AboutUsList from "./partials/AboutUsList";
import {setTab} from "../../redux/slices/general";

//___________________________________________________________

export default function AboutUsTab() {
  const dispatch = useDispatch();

  const isXsScreen = useResponsive("down", "sm");

  const [hasMore, setHasMore] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const {translate} = useLocales();

  const {
    abotUsList,
    aboutUsNext,
    abouUsLastPage,
    isLoadingAboutUs,
  } = useSelector((state) => state.general);

  const currentLanguage = getCurrentLanguage(allLangs);

  useEffect(() => {
    currentLanguage &&
    dispatch(setMetaData(aboutUsMetaData[currentLanguage.value]));
  }, [dispatch, currentLanguage]);

  const [aboutUs, setAboutUsList] = useState(abotUsList || []);

  useEffect(() => {
    if (abotUsList && abotUsList.length > 0) {
      setAboutUsList((prev) => [...prev, ...abotUsList]);
      setHasMore(aboutUsNext && aboutUs.length < abouUsLastPage);
    }
  }, [abotUsList, aboutUsNext, abouUsLastPage]);

  const handleLoadMoreClients = () => {
    if (hasMore && !isLoadingAboutUs) {
      const nextPage = currentPage + 1;

      setCurrentPage(nextPage);

      dispatch(getAboutUsService({page: nextPage, page_size: 10})).then(
        (res) => {
          if (res.payload.data.data.length === 0) {
            setHasMore(false);
          }
        }
      );
    }
  };

  useEffect(() => {

    dispatch(setTab("1"));

    setAboutUsList([]);

    setCurrentPage(1);

    setHasMore(true);

    dispatch(
      getAboutUsService({
        page: 1,
        page_size: 10,
      })
    );

  }, []);

  //________________________________________

  return (
    <>
      {/** first sections  */}
      <Box sx={firstBoxStyle}>
        <Box sx={overlayStyle}/>

        <Box sx={{display: "flex", flexDirection: "column"}}>
          <Typography sx={bigWhiteTextStyle}>
            {translate("about_us")}
          </Typography>

          <Box sx={{...borderBottomLineStyle, width: "66px"}}/>

        </Box>
      </Box>

      <AboutUsList
        length={aboutUs.length}
        handleLoadMore={handleLoadMoreClients}
        hasMore={hasMore}
        listItem={aboutUs}
        miniSize={isXsScreen}
      />

      <FooterSection/>
    </>
  );
}
