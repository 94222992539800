import React, {useEffect} from "react";
import {Box, Typography, Button} from "@mui/material";
import {useLocales} from "../../locales";
import {useTheme} from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import {getSlidersService} from "../../redux/services/general_service";
import {setTab} from "../../redux/slices/general";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper/modules";
import {Link, useNavigate} from "react-router-dom";
import LazyLoad from 'react-lazyload';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  bigWhiteTextStyle,
  buttonAiStyle,
  contentStyle,
  flexBoxStyle,
  overlayStyle,
} from "../../styles/general";

//-----------------------------------------------------

export default function Introduction() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_IMAGE_URL;

  const {translate} = useLocales();

  const theme = useTheme();

  const {slidersList} = useSelector((state) => state.general);

  useEffect(() => {
    dispatch(getSlidersService({page: 1, page_size: 3}));
  }, [dispatch]);

  return (
    <>
      <Swiper
        className="custom-swiper"
        modules={[Pagination]}
        pagination={{clickable: true}}
        spaceBetween={30}
        slidesPerView={1}
      >
        {slidersList.map((item, index) => {
          const title = theme.direction === "ltr" ? item.title_en : item.title_ar;
          const words = title.split(" ");
          const firstWord = words[0] || "";
          const secondWord = words[1] || "";
          const thirdWord = words[2] || "";
          const remainingWords = words.slice(3).join(" ");

          return (
            <SwiperSlide key={index}>
              <Box
                display="flex"
                position="relative"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="667px"
                width="100%"
                py={2}
                mt={{lg: "163px", xs: "155px", md: "163px", sm: "126px"}}
              >
                <LazyLoad height={667} offset={100} once>
                  <Box
                    component="img"
                    src={`${baseUrl}/${item.image}`}
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                    alt={title}
                    loading="lazy"
                  />
                </LazyLoad>

                <Box sx={overlayStyle}/>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: {md: "45%", xs: "80%", sm: "80%"},
                    zIndex: 1,
                  }}
                >
                  <Typography sx={{...bigWhiteTextStyle}} variant={"h1"}>
                    {firstWord}{" "}
                    <Typography
                      component="span"
                      sx={{
                        ...bigWhiteTextStyle,
                        color: "rgba(97, 204, 245, 1)",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      {secondWord} {thirdWord}
                    </Typography>{" "}
                    {remainingWords}
                  </Typography>

                  <Typography sx={contentStyle} variant={"h2"}>
                    {theme.direction === "ltr" ? item.details_en : item.details_ar}
                  </Typography>

                  <Box sx={{...flexBoxStyle}}>
                    <Link to={`/about-us`} style={{textDecoration: "none", color: "inherit"}}>
                      <Button
                        fullWidth
                        disableRipple
                        size="large"
                        type="submit"
                        variant="contained"
                        sx={buttonAiStyle}
                        /*  onClick={() => {
                            navigate(`/about-us`);
                            dispatch(setTab("about-us"));
                          }}*/
                      >
                        {translate("explore_more")}
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
