// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

import {Typography} from "@mui/material";
import React from "react";

const en = {
  home_page: `Home page`,
  consult_us: `Consult Us`,
  home: `Home`,
  about_us: `About Us`,
  practice_areas: `Practice Areas`,
  our_people: `Our People`,
  blogs: `Blogs`,
  careers: `Careers`,
  over: `Over`,
  three_decades: `Three Decades`,
  of_legal_expertise: ` of Legal Expertise`,
  offering: `Offering tailored legal services in Kuwait and internationally, specializing in corporate law, litigation, banking, and more`,
  explore_more: ` Explore More`,
  you_have_seen_it_all: `You have seen it all!`,
  key_practice_areas: `Key Practice Areas`,
  key_practice_content: `Al Adwani Law Firm offers comprehensive legal services in corporate law, litigation, arbitration, IP, and more, delivering tailored solutions to meet your goals and ambitions`,
  testimonials: `Testimonials`,
  our_happy_clients: `Our Happy Clients`,
  quick_Links: `Quick Links`,
  contact_us: `Contact us`,
  job: `Apply For A Job`,
  name: `Full Name`,
  email: `Email`,
  address: `Address`,
  phone: `Phone`,
  educational_degree: `Educational Degree`,
  applied: `Applied Position`,
  cv: `Resume/ CV Upload`,
  submit: `Submit`,
  first_name: `First name`,
  last_name: `Last name`,
  feel: ` Feel free to ask somthing are here`,
  write: `Write`,
  cookie: `Cookie Policy`,
  terms: `Terms & Conditions`,
  privacy: `Privacy Policy`,
  write_about: ` Write About Yourself`,
  company_title: `Al Adwani Law Firm`,
  adwani: `Al Adwani`,
  details: `Details`,
  cancel: `Cancel`,
  message: `Message`,
  call: `Call`,
  how: `How would you like to communicate?`,
  options: `Contact Options`,
  not_found_title: `Page Not Found`,
  not_found_message: `Oops! The page you're looking for doesn't exist.`,
  back_to_home: `Go Back Home`,
  loading: `Loading ...`,

};

export default en;
