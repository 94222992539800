import PropTypes from "prop-types";

import React, {useState, useEffect} from "react";

import {useTheme} from "@mui/material/styles";

import {
  Stack,
  AppBar,
  Toolbar,
  Box,
  Typography,
  Tabs,
  Tab,
  Link,
  IconButton,
} from "@mui/material";

import useResponsive from "../../../hooks/useResponsive";

import {useSettingsContext} from "../../../components/settings";

import {useLocales} from "../../../locales";

import {useDispatch, useSelector} from "react-redux";

// Tabs
import HomeTab from "../../../sections/tabs/HomeTab";

import AboutUsTab from "../../../sections/tabs/AboutUsTab";

import CareersTab from "../../../sections/tabs/CareersTab";

import NewsInsightstab from "../../../sections/tabs/NewsInsightstab";

import OurPeopleTab from "../../../sections/tabs/OurPeopleTab";

import PracticeAreasTab from "../../../sections/tabs/PracticeAreasTab";

import {useNavigate, useLocation} from "react-router-dom";

import {setTab} from "../../../redux/slices/general";

import {getOfficeInfoService} from "../../../redux/services/general_service";

// --- styles ---
import {
  appBarStyle,
  innerFlexBoxStyle,
  innerCenterFlexBoxStyle,
  appBarTextStyle,
  contactUsBoxStyle,
  contactUsTextStyle,
  secontAppBarStyle,
  secontAppBarStylee,
  styleTabs,
  styleClickableTab,
  styleTab,
  logoStyle,
  iconSocialStyle
} from "../../../styles/header";
import {contactUsIcon} from "../../../styles/contactUs";

//---
import {CallOutlined, MailOutline, Menu} from "@mui/icons-material";


//______________________________________________________

const TABS = [
  {
    value: "0",
    label: "home",
    path: "home",
    component: <HomeTab/>,
  },
  {
    value: "1",
    label: "about_us",
    path: "about-us",
    component: <AboutUsTab/>,
  },
  {
    value: "2",
    label: "practice_areas",
    path: "practice-areas",
    component: <PracticeAreasTab/>,
  },
  {
    value: "3",
    label: "our_people",
    path: "our-people",
    component: <OurPeopleTab/>,
  },
  {
    value: "4",
    label: "blogs",
    path: "blogs",
    component: <NewsInsightstab/>,
  },
  {
    value: "5",
    label: "careers",
    path: "careers",
    component: <CareersTab/>,
  },
];

const pathToTabMapping = {
  "/home": "0",
  "/about-us": "1",
  "/practice-areas": "2",
  "/our-people": "3",
  "/blogs": "4",
  "/careers": "5",
};

const pathToRouteMapping = {
  "0": "/home",
  "1": "/about-us",
  "2": "/practice-areas",
  "3": "/our-people",
  "4": "/blogs",
  "5": "/careers",
};

//______________________________________________________

function Header({onOpenNav}) {
  const theme = useTheme();

  const {officeInfo} = useSelector((state) => state.general);

  const location = useLocation();

  const {translate} = useLocales();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const {tab} = useSelector((state) => state.general);

  const [currentTab, setCurrentTab] = useState(tab);

  // Synchronize tempTab with currentTab

  const isMediumScreen = useResponsive("between", "md", "lg");

  const {themeLayout} = useSettingsContext();

  const {currentLang, onChangeLang} = useLocales();

  const handleChangeLang = (newLang) => {
    onChangeLang(newLang);
  };

  const isNavHorizontal = themeLayout === "horizontal";

  const isDesktop = useResponsive("up", "lg");

  const isSmallScreen = useResponsive("up", "sm");

  useEffect(() => {
    dispatch(getOfficeInfoService());
  }, []);

  useEffect(() => {

    const newTab = pathToTabMapping[location.pathname];

    if (newTab !== undefined && newTab !== tab) {
      setCurrentTab(newTab);
      dispatch(setTab(newTab));
    }
  }, [location.pathname, tab, dispatch]);

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal}
      {!isDesktop && !isSmallScreen && (
        <>
          <img
            alt="flag icon"
            src={
              currentLang.value === "en"
                ? "/assets/icons/flag_en.svg"
                : "/assets/icons/flag_ar.svg"
            }
            style={{width: "24px", height: "24px"}}
            onClick={() =>
              handleChangeLang(currentLang.value === "en" ? "ar" : "en")
            }
          />
        </>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent={"flex-end"}
      >
        {(isDesktop || isSmallScreen) && (
          <>
            <Box sx={appBarStyle}>
              {/* contact us */}
              <Box sx={innerCenterFlexBoxStyle}>

                <Box sx={{...innerFlexBoxStyle}}>
                  <CallOutlined sx={contactUsIcon}/>

                  <Box sx={{pl: "5px"}}/>
                  {officeInfo && officeInfo.phone_number ? (
                    theme.direction === "rtl" ? (
                      <Box sx={{display: "flex", alignItems: "center"}}>
                        <Typography sx={{...appBarTextStyle}}>
                          {`${officeInfo.phone_number.slice(1)}`}{" "}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: {lg: "16px", sm: "12px"},
                            fontWeight: 500,
                            color: "rgba(255, 255, 255, 1)",
                          }}
                        >
                          +
                        </Typography>
                      </Box>
                    ) : (
                      <Typography sx={{...appBarTextStyle}}>
                        {officeInfo.phone_number}
                      </Typography>
                    )
                  ) : (
                    <Typography sx={{...appBarTextStyle}}/>
                  )}
                </Box>

                <Box sx={{pl: "8px"}}/>

                <Box>
                  <a href={`mailto:${officeInfo.email}`} style={{textDecoration: "none", color: "inherit"}}>
                    <Box sx={{...innerFlexBoxStyle}}>
                      <MailOutline sx={contactUsIcon}/>

                      <Box sx={{pl: "5px"}}/>

                      <Typography sx={appBarTextStyle}>
                        {officeInfo.email}
                      </Typography>
                    </Box>
                  </a>
                </Box>
              </Box>

              {/* social & language */}
              <Box sx={{...innerFlexBoxStyle, width: {lg: "20%", sm: "30%"}}}>
                {["twitter", "linkedin", "facebook", "instagram", "whatsapp"].map((platform) => (
                  <Box key={platform} sx={iconSocialStyle}>
                    <a href={officeInfo[platform]} target="_blank" rel="noopener noreferrer">
                      <img src={`/assets/icons/${platform}.svg`} alt={`${platform} icon`}/>
                    </a>
                  </Box>
                ))}
                <Box sx={{pl: "5px", pr: "5px"}}/>
                <img
                  alt="flag icon"
                  src={currentLang.value === "en" ? "/assets/icons/flag_ar.svg" : "/assets/icons/flag_en.svg"}
                  style={{width: "24px", height: "24px"}}
                  onClick={() => handleChangeLang(currentLang.value === "en" ? "ar" : "en")}
                />
              </Box>
            </Box>
          </>
        )}
      </Stack>
    </>
  );

  const handleLogoClick = () => {
    navigate("/home");
    dispatch(setTab("0"));
  };

  const handleContactUsClick = () => {
    if (location.pathname !== "/home") {

      navigate("/home");
      dispatch(setTab("0"));

      setTimeout(() => {
        const contactUsElement = document.getElementById("contactUs");

        if (contactUsElement) {
          contactUsElement.scrollIntoView({behavior: "smooth"});
        }
      }, 500);
    } else {
      const contactUsElement = document.getElementById("contactUs");

      if (contactUsElement) {
        contactUsElement.scrollIntoView({behavior: "smooth"});
      }
    }
  };

  return (
    <>
      <AppBar>
        <Toolbar>
          {renderContent}
        </Toolbar>
        {(isDesktop || isSmallScreen) && (
          <Box sx={secontAppBarStylee}>
            {(isDesktop || isSmallScreen || isMediumScreen) && (
              <>
                <Box
                  onClick={handleLogoClick}
                  sx={{
                    ...logoStyle,
                    ...(theme.direction === "ltr"
                      ? {mr: "0px", ml: "15px"}
                      : {ml: "15px", mr: "0px"}),
                  }}
                >
                  <img src="/assets/icons/logo.svg" alt="lawyer"/>
                </Box>
                <Tabs
                  value={tab || false}
                  onChange={(event, newValue) => {
                    setCurrentTab(newValue);
                    dispatch(setTab(newValue));
                    navigate(pathToRouteMapping[newValue]);
                  }}
                  sx={styleTabs}
                  TabIndicatorProps={{
                    style: {display: "none"},
                  }}
                >
                  {TABS.map((tabItem) => (
                    <Tab
                      sx={tabItem.path === tab ? {styleClickableTab} : {styleTab}}
                      key={tabItem.value}
                      value={tabItem.value}
                      label={translate(tabItem.label)}
                    />
                  ))}
                </Tabs>

                <Box sx={{display: "flex", alignItems: "center"}}>
                  <Link
                    to="/home"
                    smooth={true}
                    duration={500}
                    onClick={handleContactUsClick}
                    style={{cursor: "pointer", textDecoration: "none"}}
                  >
                    <Box
                      sx={{
                        ...contactUsBoxStyle,
                        cursor: "pointer",
                        ...(theme.direction === "ltr"
                          ? {mr: "0px", ml: "15px"}
                          : {ml: "15px", mr: "0px"}),
                      }}
                    >
                      <Typography sx={contactUsTextStyle}>
                        {translate("consult_us")}
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              </>
            )}
          </Box>
        )}

        {!isDesktop && !isSmallScreen && (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={"100%"} component="nav">
            <Box sx={secontAppBarStyle}>

              <IconButton onClick={onOpenNav}>
                <Menu color={"primary"}/>
              </IconButton>

              <Box display={"flex"} alignItems={"center"}>
                <Box onClick={handleLogoClick} sx={logoStyle}>
                  <img src="/assets/icons/logo.svg" alt="AlAdwani LawFirm"/>
                </Box>
              </Box>

            </Box>
          </Box>
        )}
      </AppBar>
    </>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default Header;