import React from 'react';
import {Box, Typography, Button} from "@mui/material";
import {useLocales} from "../locales";

const NotFoundPage = () => {

  const {translate} = useLocales();

  return (
    <Box pt={10} mt={10} textAlign={"center"}>

      <Typography fontWeight={700} color={"#4e73e0"} fontSize={"54px"}>
        404 <br/>
        {translate("not_found_title")}
      </Typography>

      <Typography fontWeight={500} fontSize={"32px"} mb={2}>
        {translate("not_found_message")}
      </Typography>

      <Button
        href="/"
        sx={{
          backgroundColor: "#4e73e0",
          color: "#fff",
          '&:hover': {
            backgroundColor: "#3a59a5",
          },
        }}
      >
        {translate("back_to_home")}
      </Button>

    </Box>
  );
};

export default NotFoundPage;
