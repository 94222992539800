import React from "react";

// mui
import {Box, Typography, Button, Grid, CircularProgress} from "@mui/material";

import {useLocales} from "../../locales";

import {useTheme} from "@mui/material/styles";

import {useDispatch, useSelector} from "react-redux";

import {Link, useNavigate} from "react-router-dom";

// styles
import {
  borderBottomLineStyle,
  buttonAiStyle,
  boxTitleStyle,
  flexBoxStyle,
  gridStyle,
  personStyle,
  textStyle,
  titleStyle
} from "../../styles/general";

//_______________________________________________________

export default function AboutUs() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_IMAGE_URL;

  const {translate} = useLocales();

  const theme = useTheme();

  const {abotUsHome, isLoadingAboutUs} = useSelector(
    (state) => state.general
  );

  return (
    <Box mt={2} mb={6}>
      <Box sx={boxTitleStyle}>
        <Typography sx={titleStyle} variant={"h2"}> {translate("about_us")}</Typography>
        <Box sx={{...borderBottomLineStyle, width: "66px"}}/>
      </Box>
      <Box sx={gridStyle}>
        <Grid container columnSpacing={{lg: 2, sm: 4}}>
          <Grid item xs={12} sm={6}>
            {isLoadingAboutUs ? (
              <Box
                display={"flex"} width={"100%"}
                justifyContent={"center"} alignItems={"center"}
              >
                <CircularProgress color="secondary"/>
              </Box>
            ) : (
              <Box
                component="img"
                src={`${baseUrl}/${abotUsHome.image}`}
                sx={personStyle}
                alt={"ayyad al adwani"}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              display={"flex"} flexDirection={"column"}
              justifyContent={"center"} alignItems={"center"}
              height={"100%"}
            >
              <Typography sx={{...textStyle}} variant={"h3"}>
                {theme.direction === "ltr"
                  ? abotUsHome.details_en
                  : abotUsHome.details_ar}
                {}
              </Typography>

              <Box sx={flexBoxStyle}>
                <Link to={`/about-us`} style={{textDecoration: "none", color: "inherit"}}>
                  <Button
                    fullWidth
                    disableRipple
                    size="large"
                    type="submit"
                    variant="Contained"
                    sx={buttonAiStyle}
                  >
                    {translate("explore_more")}
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
