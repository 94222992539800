export const appBarStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  pl: {lg: "30px", sm: "2px"},
  pr: {lg: "30px", sm: "2px"},
};

export const innerFlexBoxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

export const innerCenterFlexBoxStyle = {
  display: "flex",
  alignItems: "center",
  //justifyContent: "space-between",
};

export const appBarTextStyle = {
  fontSize: {lg: "16px", sm: "12px"},
  fontWeight: 500,
  color: "rgba(255, 255, 255, 1)",
  pl: "3px",
  pr: "3px",
};

export const contactUsBoxStyle = {
  p: {lg: "38px", sm: "20px", md: "30px", xs: "30px"},
  background: "rgba(97, 204, 245, 1)",
  display: "flex",
  alignItems: "center",
  // mr: { sm: "15px", md: "0px" },
};

export const contactUsTextStyle = {
  fontSize: {lg: "16px", sm: "15px"},
  fontWeight: {lg: 700, sm: 600},
  color: "rgba(255, 255, 255, 1)",
  width: "100%",
};

export const secontAppBarStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  //mt: { xs: "51.3px", lg: "0px", sm: "63.5px" },
  background: "#fff",
  //p: "18px",
  pl: "5px",
  pr: "5px",
};

export const secontAppBarStylee = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  background: "#fff",
  // mt: { xs: "51.3px", lg: "0px", sm: "63.5px" },
};

export const styleTabs = {
  display: "flex",
  // flexDirection: "column",
  bgcolor: "rgba(255, 255, 255, 0.9)",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  //width: "100%",
  //pt: "20px",

  "& button.Mui-selected": {
    color: "#61CCF5",
    fontSize: "16px",
    fontWeight: 550,
    lineHeight: "28px",
    minHeight: "40px",
  },

  "& .MuiTabs-flexContainer": {
    display: "flex",
    // flexDirection: "column",
    alignItems: "center",
  },

  "& .MuiTabs-scroller": {
    display: "flex",
    // flexDirection: "column",
    alignItems: "center",
  },

  "& .MuiButtonBase-root:not(.Mui-selected)": {
    color: "rgba(0, 0, 0, 1)",
    fontSize: "16px",
    fontWeight: 500,
    minHeight: "40px",
    lineHeight: "20px",
  },
};

export const styleClickableTab = {
  borderRadius: "8px",
  minWidth: "134px",
  height: "48px",
  // width: "100%",
};

export const styleTab = {
  minHeight: "35px",
  minWidth: "134px",
  height: "48px",
  // width: "100%",
};

export const logoStyle = {
  width: {lg: "182px", sm: "190px", md: "182px", xs: "auto"},
  height: {lg: "100px", sm: "61px", md: "100px", xs: "auto"},
};

export const iconSocialStyle = {
  width: {lg: "20px", sm: "16px", xs: "11.86px"},
  height: {lg: "20px", sm: "16px", xs: "11.86px"},
  flexShrink: 0,
};