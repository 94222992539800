import {useEffect} from "react";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
import { ThemeSettings } from "./components/settings";
import SEOMetaTags from "./components/seo";
//----
import axios from "axios";
import {useSelector} from "./redux/store";
//--
import {initGA, useAnalytics} from './analytics';
const trackingID = 'G-NBZB6PW3CQ';

function App() {
  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("Token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

    const metaData = useSelector((state) => state.seo);

    useEffect(() => {
        initGA(trackingID);
    }, []);

    useAnalytics();

  return (
    <ThemeProvider>
      <ThemeSettings>
        <ThemeLocalization>
          <Router />
          <SEOMetaTags data={metaData}/>
        </ThemeLocalization>
      </ThemeSettings>
    </ThemeProvider>
  );
}

export default App;
