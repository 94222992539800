import React, {useEffect} from "react";

// mui
import {Box, Typography, Button, Grid} from "@mui/material";

import {useLocales} from "../../locales";

import {useTheme} from "@mui/material/styles";

import {useDispatch, useSelector} from "react-redux";

import {getPeopleService} from "../../redux/services/general_service";

import {useNavigate} from "react-router-dom";

import useResponsive from "../../hooks/useResponsive";

import {setTab} from "../../redux/slices/general";
import {borderBottomLineStyle, buttonAiStyle} from "../../styles/general";
//____________________________________________________

//---------- Styles -----------

const secondGrid = {
  // display: "flex",
  // flexDirection: "column",
  background: "rgba(245, 245, 245, 1)",
  pl: {xs: "30px", sm: "0px"},
  pr: {xs: "30px", sm: "0px"},
};

const boxTitleStyle = {
  display: "flex",
  alignItems: "Center",
  flexDirection: "column",
  mb: {xs: "30px", sm: "30px", lg: "70px"},
  zIndex: 10,
};

const titleStyle = {
  fontSize: {lg: "32px", sm: "24px", xs: "24px", md: "32px"},
  fontWeight: 700,
  color: "rgba(30, 24, 76, 1)",
  textAlign: "center",
  mb: "5px",
  mt: "50px",
};

const nameStyle = {
  fontSize: {lg: "24px", xs: "24px", md: "24px", sm: "24px"},
  fontWeight: 600,
  color: "#2A2555",
  textAlign: "center",
  mt: "20px",
};

const jobStyle = {
  fontSize: {lg: "20px", xs: "20px", md: "20px", sm: "20px"},
  fontWeight: 600,
  color: "#61CCF5",
  textAlign: "center",
};

const ourPeopleImg = {
  width: {lg: "343px", sm: "auto", xs: "100", md: "392px"},
  height: {lg: "370px", sm: "auto", xs: "auto", md: "379px"},
  margin: "auto",
};

//_______________________________________________________
export default function People() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_BASE_IMAGE_URL;

  const {translate} = useLocales();

  const theme = useTheme();

  const isDesktop = useResponsive("up", "lg");

  const isSmallScreen = useResponsive("up", "sm");

  const isMediumScreen = useResponsive("between", "md", "lg");

  const isXsScreen = useResponsive("down", "sm");

  const {peopleList} = useSelector((state) => state.general);

  useEffect(() => {
    dispatch(
      getPeopleService({
        page: 1,
        page_size: 3,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Box sx={{...secondGrid, mt: "0px", pb: "50px"}}>
        <Box sx={boxTitleStyle}>
          <Typography sx={titleStyle} variant={"h2"}>{translate("our_people")}</Typography>
          <Box sx={borderBottomLineStyle}/>
        </Box>
        {(isDesktop || !isSmallScreen || isMediumScreen) && (
          <Grid container columnSpacing={2} mt="20px" pb="20px">
            {peopleList.slice(0, 3).map((item) => (
              <Grid item xs={12} sm={4} lg={4} mb={{xs: "30px"}}>
                <Box sx={ourPeopleImg}>
                  <img alt="person image" src={`${baseUrl}/${item.image}`}/>
                </Box>
                <Typography sx={nameStyle} variant={"h3"}>
                  {" "}
                  {theme.direction === "ltr" ? item.title_en : item.title_ar}
                </Typography>

                <Typography sx={jobStyle} variant={"h4"}>
                  {" "}
                  {theme.direction === "ltr"
                    ? item.details_en
                    : item.details_ar}
                </Typography>
              </Grid>
            ))}
          </Grid>
        )}

        {isSmallScreen && !isXsScreen && !isDesktop && !isMediumScreen && (
          <Grid container columnSpacing={2} mt="20px" pb="20px" pl="20px" pr="20px">
            {peopleList.slice(0, 2).map((item) => (
              <Grid item xs={12} sm={6} lg={4} mb={{xs: "30px"}}>
                <Box sx={ourPeopleImg}>
                  <img alt="our team image" src={`${baseUrl}/${item.image}`}/>
                </Box>
                <Typography sx={nameStyle}>
                  {" "}
                  {theme.direction === "ltr" ? item.title_en : item.title_ar}
                </Typography>

                <Typography sx={jobStyle}>
                  {" "}
                  {theme.direction === "ltr"
                    ? item.details_en
                    : item.details_ar}
                </Typography>
              </Grid>
            ))}
          </Grid>
        )}

        {!isDesktop && !isSmallScreen && !isMediumScreen && (
          <Grid container columnSpacing={2} mt="20px" pb="20px">
            {peopleList.slice(0, 1).map((item) => (
              <Grid item xs={12} sm={6} lg={4} mb={{xs: "30px"}}>
                <Box sx={ourPeopleImg}>
                  <img alt="our team image" src={`${baseUrl}/${item.image}`}/>
                </Box>
                <Typography sx={nameStyle}>
                  {" "}
                  {theme.direction === "ltr" ? item.title_en : item.title_ar}
                </Typography>

                <Typography sx={jobStyle}>
                  {" "}
                  {theme.direction === "ltr"
                    ? item.details_en
                    : item.details_ar}
                </Typography>
              </Grid>
            ))}
          </Grid>
        )}
        <Box display={"flex"} alignItems={"center"} width={"100%"} justifyContent={"center"}>
          <Button
            fullWidth
            disableRipple
            size="large"
            type="submit"
            variant="contained"
            sx={{...buttonAiStyle, margin: "auto"}}
            onClick={(event) => {
              navigate(`/our-people`);
              dispatch(setTab("our-people"));
            }}
          >
            {translate("explore_more")}
          </Button>
        </Box>
      </Box>
    </>
  );
}
