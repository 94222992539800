import { Box, Typography } from "@mui/material";

import { useDispatch } from "react-redux";

import { useTheme } from "@mui/material/styles";

//_________________________________________________________

//* ------- Styles --------

const blackTitle = {
  fontSize: { lg: "24px", md: "24px", sm: "24px", xs: "24px" },
  fontWeight: 600,
  color: "#2A2555",
  textAlign: "center",
  mt: "20px",
};

const cardImgStyle = {
  width: "100%",
  height: { lg: "370px", sm: "370px", xs: "370px", md: "370px" },
  mb: { xs: "20px", sm: "0px" },
  margin: "auto",
};

const bigColumnFlexBox = {
  display: "flex",
  margin: "auto",
  flexDirection: "column",
  background: "rgba(255, 255, 255, 1)",
  pb: { xs: "66px", sm: "0px" },
  alignItems: "center",
  // height: { lg: "450px", sm: "329px", xs: "auto", md: "400px" },
  width: { lg: "343px", sm: "280px", xs: "auto", md: "343px" },
  //border: "1px solid #ADADAD",
  mb: "40px",
};

const innerFlexBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
};

const blueTitle = {
  fontSize: { lg: "20px", md: "20px", sm: "20px", xs: "20px" },
  fontWeight: 600,
  color: "#61CCF5",
  textAlign: "center",
};
//___________________________________________________________
export default function OurPeopleCard({ item }) {
  const dispatch = useDispatch();

  const theme = useTheme();

  const baseUrl = process.env.REACT_APP_BASE_IMAGE_URL;

  //________________________________________

  return (
    <>
      <Box sx={bigColumnFlexBox}>
        <Box
          component="img"
          src={`${baseUrl}/${item.image}`}
          sx={cardImgStyle}
          alt={"our people"}
        ></Box>
        <Box sx={innerFlexBox}>
          <Typography sx={{ ...blackTitle }}>
            {" "}
            {theme.direction === "ltr" ? item.title_en : item.title_ar}
          </Typography>

          <Typography sx={{ ...blueTitle }}>
            {" "}
            {theme.direction === "ltr" ? item.details_en : item.details_ar}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
